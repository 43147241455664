import useCustomers from '@/compositions/customers';
import { Customer } from '@/types/customers';
import CustomerEditButton from '@/components/Customers/CustomerEditButton';
import { getRandomString } from '@/classes/random';
import { pickBy } from 'lodash';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'CustomerSearchField',
    components: {
        CustomerEditButton
    },
    props: {
        customerId: {
            type: String,
            required: false,
            default: undefined
        }
    },
    // eslint-disable-next-line sonarjs/no-duplicate-string
    emits: ['update:customerId'],
    setup() {
        return { ...useCustomers() };
    },
    data: () => ({
        localFilter: {
            field: null,
            value: '',
            fromDate: null,
            toDate: null
        },
        inputFieldId: getRandomString(),
        searchValue: ''
    }),
    mounted() {
        if (this.customerId !== undefined) {
            const customer = this.customers[this.customerId];
            if (customer !== undefined) {
                this.searchValue = this.getCustomerName(customer);
            }
        }
    },
    methods: {
        updateFilter(id, searchText = '') {
            this.$emit('update:customerId', id);
            this.searchValue = searchText;
        },
        customersFiltered() {
            if (this.searchValue === '') {
                return {};
            }
            const result = pickBy(this.customers, customer => {
                const customerString = customer.firstname + ' ' + customer.lastname + ' ' + customer.email;
                return customerString.toLowerCase().includes(this.searchValue.toLowerCase());
            });
            return (result !== null) ? result : {};
        },
        getCustomerName(customer) {
            return customer.firstname + ' ' + customer.lastname +
                ' ( ' + customer.email + ' )';
        },
        getEmptyCustomer() {
            return new Customer();
        }
    }
});
