import { defineComponent } from 'vue';
import EditProfileForm from '@/components/EditProfile';
import ModalBox from '@/components/ModalBox';
import useAuth from '@/compositions/auth';
import { useToast } from 'vue-toastification';
export default defineComponent({
    name: 'CustomerEditButton',
    components: {
        EditProfileForm,
        ModalBox
    },
    props: {
        customer: {
            type: Object,
            required: true
        },
        isNew: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: String,
            default: ''
        }
    },
    setup() {
        return { ...useAuth(), toast: useToast() };
    },
    data: () => ({
        showModal: false
    }),
    methods: {
        async profileUpdated() {
            if (this.customer === null)
                return;
            if (this.isNew)
                this.$store.direct.dispatch.customers.add(this.customer);
            else {
                try {
                    await this.$store.direct.dispatch.customers.update({ id: this.customerId, update: this.customer });
                    this.toast.success('Klantinformatie is aangepast');
                }
                catch (error) {
                    this.toast.error(`Er is een fout opgetreden bij het aanpassen van de informatie ${error}`);
                }
            }
            this.showModal = false;
        }
    }
});
