import store from '@/store';
import { onBeforeUnmount, onMounted, computed } from 'vue';
const useCustomers = () => {
    onMounted(async () => {
        await store.dispatch.customers.subscribe();
    });
    onBeforeUnmount(async () => {
        await store.dispatch.customers.unsubscribe();
    });
    const customers = computed(() => {
        return store.state.customers.customers;
    });
    return {
        customers
    };
};
export default useCustomers;
